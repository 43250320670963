import { Edit, FilterAlt, PersonAdd } from '@mui/icons-material';
import { Box, Button, Link, Typography } from '@mui/material';
import { DEFAULT_GRID_COL_TYPE_KEY, DataGridPro, GridColDef, GridRowModel, GridRowParams, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton, GridToolbarQuickFilter, GridValidRowModel, getGridDefaultColumnTypes, useGridApiRef } from '@mui/x-data-grid-pro';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useContext, useEffect, useMemo, useState } from 'react';
import { LayoutContext, fetchGet, fetchPut, hasRole, newGuid, persistStorage } from 'wcz-layout';
import AddAndEditDialog from '../components/headcountSummary/AddAndEditDialog';
import { headList } from '../models/Head';
import HeadcountSummaryModel from '../models/HeadcountSummaryModel';
import Project, { projectList } from '../models/Project';
import { apiUrl } from '../utils/BaseUrl';

const newEmployeeEmpty: HeadcountSummaryModel = {
    id: "",
    employer: "SMS-P",
    departmentId: "",
    departmentDescription: "",
    firstName: "Supporter",
    lastName: "",
    employeeId: "",
    project: "",
    line: "",
    station: "",
    position: "",
    workStatus: "",
    contractStatus: "Active",
    workshiftStart: "",
    workshiftEnd: "",
    isNew: false
}
const defaultColumnTypes = getGridDefaultColumnTypes();

interface CustomToolbarProps {
    handleClickOpenAddDialog: () => void,
    handleClickNotAssignedEmployees: () => void,
    variantNotAssignedEmployees: "contained" | "outlined" | "text",
    countNotAssignedEmployees: string,
    showEditButton: boolean,
    handleClickOpenEditDialog: () => void,
}

function CustomToolbar(props: CustomToolbarProps) {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            {
                props.countNotAssignedEmployees !== "" &&
                <Button color="primary" variant={props.variantNotAssignedEmployees} size="small" startIcon={<FilterAlt />} sx={{ ml: '5px' }} onClick={props.handleClickNotAssignedEmployees}>Not assigned employees {props.countNotAssignedEmployees}</Button>
            }
            <Button color="primary" size="small" startIcon={<PersonAdd />} sx={{ ml: '5px' }} onClick={props.handleClickOpenAddDialog}>Add new</Button>
            {
                props.showEditButton &&
                <Button color="primary" size="small" startIcon={<Edit />} sx={{ ml: '5px' }} onClick={props.handleClickOpenEditDialog}>Edit employee</Button>
            }
            <GridToolbarQuickFilter sx={{ marginLeft: "auto" }} />
        </GridToolbarContainer>
    );
}

export default function HeadcountSummary() {
    const { user } = useContext(LayoutContext);
    const [rows, setRows] = useState([] as HeadcountSummaryModel[]);
    const gridApiRef = useGridApiRef();
    const [employee, setEmployee] = useState({} as HeadcountSummaryModel);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [showEditButton, setShowEditButton] = useState(false);
    const [variantNotAssignedEmployees, setVariantNotAssignedEmployees] = useState("text");
    const [countNotAssignedEmployees, setCountNotAssignedEmployees] = useState("");
    const headOf = headList.filter(head => head.employeeId === user.id);
    const isITAdmin = useMemo(() => hasRole(["wcz-developers"]), [user]);
    const hasExceptionToSeeAll = useMemo(() => user.name === "Weidi Wu", [user]);
    const { data = [], refetch: refetchHsEmployees } = useQuery<HeadcountSummaryModel[]>(["hs-employees"], ({ signal }) => fetchGet(`${apiUrl}/sot/v1/hsemployee`, signal), {
        onSuccess: (data) => {
            let filteredRows = data.filter(empl => isITAdmin || hasExceptionToSeeAll || headOf.some(h => h.departmentId === empl.departmentId));
            let countOfNotAssignedEmployees = filteredRows.filter(r => (!r.project || !r.line || !r.station) && r.contractStatus === "Active").length;
            if (variantNotAssignedEmployees === "outlined" && countOfNotAssignedEmployees > 0) {
                filteredRows = filteredRows.filter(r => (!r.project || !r.line || !r.station) && r.contractStatus === "Active");
            }

            setRows(filteredRows);
        }
    });

    useEffect(() => {
        let count = rows.filter(r => (!r.project || !r.line || !r.station) && r.contractStatus === "Active").length;
        if (count === 0) {
            setCountNotAssignedEmployees("");
            setVariantNotAssignedEmployees("text");
        }
        else {
            setCountNotAssignedEmployees(`(${count})`);
        }
    }, [rows]);

    const columns: GridColDef[] = useMemo(() => [
        { field: 'employer', headerName: "Employer", width: 150 },
        { field: 'departmentId', headerName: "Department Code", width: 150 },
        { field: 'departmentDescription', headerName: "Department", width: 250 },
        { field: 'firstName', headerName: "First Name", width: 200 },
        { field: 'lastName', headerName: "Last Name", width: 125 },
        {
            field: 'employeeId', headerName: "Employee No.", width: 150,
            renderCell: (params) => <Link href={`/daily-workload/${params.value}`}>{params.value}</Link>
        },
        {
            field: 'project', headerName: "Project", width: 250, editable: true, type: "singleSelect", filterOperators: defaultColumnTypes[DEFAULT_GRID_COL_TYPE_KEY].filterOperators!,
            valueOptions: ({ row }) => {
                if (!row) {
                    // The row is not available when filtering this column
                    return projectList.map((project: Project) => project.name);
                }

                return projectList.filter(p => p.departments.includes(row.departmentId)).map((project: Project) => project.name);
            },
        },
        {
            field: 'line', headerName: "Line", width: 150, editable: true, type: "singleSelect", filterOperators: defaultColumnTypes[DEFAULT_GRID_COL_TYPE_KEY].filterOperators!,
            valueOptions: ({ row }) => {
                if (!row) {
                    // The row is not available when filtering this column
                    let lineOptions = projectList.map((project: Project) => project.lines).flat(1).filter((val, i, arr) => arr.indexOf(val) === i);
                    //lineOptions.unshift('');
                    return lineOptions;
                }

                return projectList.find(p => p.name === row.project && p.departments.includes(row.departmentId))?.lines.map((line: string) => line) ?? [];
            },
        },
        {
            field: 'station', headerName: "Station", width: 150, editable: true, type: "singleSelect", filterOperators: defaultColumnTypes[DEFAULT_GRID_COL_TYPE_KEY].filterOperators!,
            valueOptions: ({ row }) => {
                if (!row) {
                    // The row is not available when filtering this column
                    return projectList.map((project: Project) => project.stations).flat(1).filter((val, i, arr) => arr.indexOf(val) === i);
                }

                return projectList.find(p => p.name === row.project && p.departments.includes(row.departmentId))?.stations.map((station: string) => station) ?? [];
            },
        },
        { field: 'position', headerName: "Job Position (Contract)", width: 200 },
        { field: 'workStatus', headerName: "Working Status", width: 150 },
        { field: 'contractStatus', headerName: "Contract Status", width: 150 },
        { field: 'workshiftStart', headerName: "Working Shift - Start", width: 200 },
        { field: 'workshiftEnd', headerName: "Working Shift - End", width: 200 },
        // eslint-disable-next-line
    ] as GridColDef[], []);

    function handleTableStateChanged() {
        let state: GridInitialStatePro = gridApiRef.current.exportState();
        state = { ...state, preferencePanel: undefined };

        persistStorage.setObject(`HeadcountSummary-Table-state`, state);
    }

    const handleClickOpenAddDialog = () => {
        setDialogTitle("Add new employee")
        setEmployee({ ...newEmployeeEmpty, id: newGuid(), isNew: true });
        setOpenDialog(true);
    };

    const handleClickOpenEditDialog = () => {
        setDialogTitle("Edit employee");        
        setOpenDialog(true);
    }

    const handleClickNotAssignedEmployees = () => {        
        if (variantNotAssignedEmployees === "text") {
            setVariantNotAssignedEmployees("outlined");
            var filteredRows = rows.filter(r => (!r.project || !r.line || !r.station) && r.contractStatus === "Active");
            setRows(filteredRows);
        }
        else {
            setVariantNotAssignedEmployees("text");
            setRows(data.filter(empl => isITAdmin || hasExceptionToSeeAll || headOf.some(h => h.departmentId === empl.departmentId)));
        }
    };

    const handleClickOnRow = (params: GridRowParams) => {
        let foundEmployee = rows.find(r => r.id === params.id);
        if (foundEmployee === undefined) {
            setShowEditButton(false);
            return;
        }

        if (foundEmployee.employer === "SMS-P" || foundEmployee.employer === "3rd party" || foundEmployee.employer === "WIH" || foundEmployee.employer === "WSPH") {
            setEmployee(foundEmployee);
            setShowEditButton(true);
        }
        else {
            setShowEditButton(false);
        }
    }

    const { mutateAsync: putUpdatedEmployee } = useMutation((request: any) => fetchPut(`${apiUrl}/sot/v1/hsemployee/${request.id}`, request), {
        onSuccess: () => refetchHsEmployees()
    });

    async function processRowUdpate(newRow: GridRowModel, oldRow: GridRowModel): Promise<GridValidRowModel> {
        if (oldRow.project !== newRow.project) {
            let lines = projectList.find(p => p.name === newRow.project && p.departments.includes(newRow!.departmentId))?.lines ?? [];
            newRow.line = lines?.length === 1 ? lines[0] : "";
            newRow.station = "";     
        }

        await putUpdatedEmployee(newRow);
        return newRow;
    }

    return (
        <Box>
            <Typography variant="h5" color="text.secondary" sx={{ pl: "25px", pt: "15px" }} gutterBottom component="div">Headcount Summary</Typography>
            <Box
                sx={{
                    display: 'flex', width: '100%', maxWidth: '2580px', height: '82vh',
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: '600',
                    },
                }}
            >
                <DataGridPro columns={columns} rows={rows} density="compact" sx={{ padding: '10px 20px', margin: '0px 20px' }}
                    apiRef={gridApiRef}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                    slotProps={{
                        toolbar: { showQuickFilter: true, handleClickOpenAddDialog, handleClickNotAssignedEmployees, variantNotAssignedEmployees, countNotAssignedEmployees, showEditButton, handleClickOpenEditDialog }
                    }}
                    processRowUpdate={processRowUdpate}
                    onRowClick={handleClickOnRow}
                    initialState={persistStorage.getObject(`HeadcountSummary-Table-state`) ?? undefined}
                    onSortModelChange={handleTableStateChanged}
                    onFilterModelChange={handleTableStateChanged}
                    onPinnedColumnsChange={handleTableStateChanged}
                    onColumnOrderChange={handleTableStateChanged}
                    onColumnVisibilityModelChange={handleTableStateChanged}
                    onColumnWidthChange={handleTableStateChanged}                    
                />
            </Box>

            <AddAndEditDialog open={openDialog} setOpen={setOpenDialog} title={dialogTitle} employee={employee} setEmployee={setEmployee} rows={rows} setRows={setRows} />
        </Box>
    )
}