import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";

interface EditLineDialogProps {
    lineId: string,
    setLineIdToEdit: (lineId: string) => void,
}

export default function EditLineDialog(props: EditLineDialogProps) {
    const { lineId, setLineIdToEdit } = props;

    const handleClickCancelDialog = () => {
        setLineIdToEdit("");
    };

    return (
        <Dialog open={Boolean(lineId)} fullWidth>
            <DialogTitle>Edit line</DialogTitle>

            <DialogContent>
                <TextField
                    variant="standard"
                    margin="dense"
                    id="name"
                    label={<>Line name<span style={{ color: "red" }}>*</span></>}
                    type="text"
                    value={lineId}
                    fullWidth
                    onChange={(event) => {

                    }}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClickCancelDialog}>Cancel</Button>
                <Button variant="contained">Save</Button>
            </DialogActions>
        </Dialog>
    );
}