import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { fetchGet } from "wcz-layout";
import { EmployeeDto } from "../../models/EmployeeDto";

interface AddLeaderDialogProps {
    open: boolean,
    setOpen: (open: boolean) => void,
}

export default function AddLeaderDialog(props: AddLeaderDialogProps) {
    const { open, setOpen } = props;

    const { data: employeeList = [] } = useQuery<EmployeeDto[]>(["ps-employees"], ({ signal }) => fetchGet(`https://api.wistron.eu/ps/v1/employee?search=departmentId=in=(CWZE10,CWZE11,CWZE12,CWZE13,CWZE20,CWZE21,CWZE22,CWZE23,CWZ620);(position==*Leader*,position==*Super*,position==*Manager*)&fields=employeeId,nameTitleCase,position`, signal), {
        enabled: open
    });

    const handleClickCancelDialog = () => {
        setOpen(false);
    };

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Add leader</DialogTitle>

            <DialogContent>
                <FormControl variant="standard" fullWidth sx={{ mt: '8px', mb: '4px' }}>
                    <InputLabel id="function">Function<span style={{ color: "red" }}>*</span></InputLabel>
                    <Select
                        margin="dense"
                        id="function"
                        type="text"
                        value={""}
                        fullWidth
                        onChange={(event) => { } }
                    >
                        <MenuItem value={"Leader"}>Leader</MenuItem>
                        <MenuItem value={"Supervisor"}>Supervisor</MenuItem>
                        <MenuItem value={"Manager"}>Manager</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="standard" fullWidth sx={{ mt: '8px', mb: '4px' }}>
                    <InputLabel id="leaderEmployeeId">Leader<span style={{ color: "red" }}>*</span></InputLabel>
                    <Select
                        margin="dense"
                        id="leaderEmployeeId"
                        type="text"
                        value={""}
                        fullWidth
                        onChange={(event) => {
                            ;
                        }}
                    >
                        {employeeList.map((employee: EmployeeDto) => <MenuItem key={employee.employeeId} value={employee.employeeId}>{employee.nameTitleCase} ({employee.employeeId})</MenuItem>)}
                    </Select>
                </FormControl>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClickCancelDialog}>Cancel</Button>
                <Button variant="contained">Save</Button>
            </DialogActions>
        </Dialog>
    );
}