export default interface Department {
    id: string,
    description: string,
};

export const departmentList: Department[] = [
    { id: "CWZE10", description: "PCBA Repair" },
    { id: "CWZE11", description: "Astro Repair" },
    { id: "CWZE12", description: "Non Astro Repair" },
    { id: "CWZE13", description: "BGA Repair" },
    { id: "CWZE20", description: "Defective E2E Dept." },
    { id: "CWZE21", description: "SPF Defective Receiving" },
    { id: "CWZE22", description: "Omega Project FRU Screening" },
    { id: "CWZE23", description: "All Projects RTV Ship" },
    { id: "CWZ620", description: "Quality Control" },
    { id: "CWZB00", description: "Warehouse Dept." },
    { id: "CWZE30", description: "Engineering dpt." },
    { id: "CWZXXX", description: "Company Support" },
];