import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";

interface AddStationDialogProps {
    open: boolean,
    setOpen: (open: boolean) => void,
}

export default function AddStationDialog(props: AddStationDialogProps) {
    const { open, setOpen } = props;

    const handleClickCancelDialog = () => {
        setOpen(false);
    };

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Add station</DialogTitle>

            <DialogContent>
                <TextField
                    variant="standard"
                    margin="dense"
                    id="name"
                    label={<>Station name<span style={{ color: "red" }}>*</span></>}
                    type="text"
                    value={""}
                    fullWidth
                    onChange={(event) => {

                    }}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClickCancelDialog}>Cancel</Button>
                <Button variant="contained">Save</Button>
            </DialogActions>
        </Dialog>
    );
}