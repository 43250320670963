export default interface Project {    
    name: string,
    departments: string[],
    lines: string[],
    stations: string[]
};

export const projectList: Project[] = [
    {
        name: "IRR",
        departments: ["CWZE11"],
        lines: ["1", "2"],
        stations: ["Inspection", "Repair 1", "Repair 2", "Rework", "Test", "VC", "Runner", "Training", "Leader"]
    },
    {
        name: "HP FLEX",
        departments: ["CWZE11"],
        lines: ["-"],
        stations: ["Inspection", "Repair 1", "Repair 2", "Rework", "Test", "VC", "Runner", "Training", "Leader"]
    },
    {
        name: "Dell OOW",
        departments: ["CWZE12"],
        lines: ["-"],
        stations: ["Inspection", "Repair 1", "Repair 2", "Rework", "Test", "VC", "Runner", "Training", "Leader"]
    },
    {
        name: "Dell IW",
        departments: ["CWZE12"],
        lines: ["-"],
        stations: ["Inspection", "Repair 1", "Repair 2", "Rework", "Test", "VC", "Runner", "Training", "Leader"]
    },
    {
        name: "SONY",
        departments: ["CWZE12"],
        lines: ["-"],
        stations: ["Repair", "Test", "Runner", "Training", "Leader"]
    },
    {
        name: "BGA",
        departments: ["CWZE13"],
        lines: ["-"],
        stations: ["Inspection", "BGA Replacement - IRR", "BGA Replacement - Dell", "BGA Replacement - HP FLEX", "BGA Remove", "TC", "Runner", "Training", "Leader"]
    },
    {
        name: "Re-ball",
        departments: ["CWZE13"],
        lines: ["-"],
        stations: ["Inspection", "CPU Reball & redemption", "Comp. salvage", "Runner", "Training", "Leader"]
    },
    {
        name: "MB Repair",
        departments: ["CWZE10"],
        lines: ["-"],
        stations: ["Trainer", "Assistant"]
    },
    {
        name: "FAE",
        departments: ["CWZE10"],
        lines: ["-"],
        stations: ["FAE - IRR", "FAE - HP FLEX", "FAE - Dell IW", "FAE - Dell OOW"]
    },
    {
        name: "WUR",
        departments: ["CWZE11"],
        lines: ["-"],
        stations: ["Inspection", "Dock", "KB", "HPTC", "Training", "Leader"]
    },
    {
        name: "E2E",
        departments: ["CWZE20"],
        lines: ["-"],
        stations: ["Assistant"]
    },
    {
        name: "HP Def. Receiving",
        departments: ["CWZE21"],
        lines: ["-"],
        stations: ["Pre-Sorting", "Manual Receive", "ARL Receive", "Exception Receive", "DMR Specialist", "Scrap", "Runner", "Training", "Keeper", "Leader"]
    },
    {
        name: "HP FRU",
        departments: ["CWZE22"],
        lines: ["-"],
        stations: ["Inspection", "MB CID Check", "MB Inspection", "MB Test", "SSD Screen", "Memory Screen", "Runner", "Training", "Leader"]
    },
    {
        name: "RTV",
        departments: ["CWZE23"],
        lines: ["-"],
        stations: ["All-Included - HP OSSP", "All-Included - IRR", "All-Included - Dell IW", "All-Included - HP FLEX", "Shipping Box Assembly", "Training", "Leader"]
    },
    {
        name: "HP OSSP SPF",
        departments: ["CWZ620"],
        lines: ["-"],
        stations: ["Kitting OBA", "IQC", "RTV IQC", "FQC"]
    },
    {
        name: "IRR",
        departments: ["CWZ620"],
        lines: ["-"],
        stations: ["VQC", "FQC", "IQC"]
    },
    {
        name: "HP FLEX",
        departments: ["CWZ620"],
        lines: ["-"],
        stations: ["VQC", "IQC"]
    },
    {
        name: "Dell",
        departments: ["CWZ620"],
        lines: ["-"],
        stations: ["VQC", "IQC"]
    },
    {
        name: "HPTC",
        departments: ["CWZ620"],
        lines: ["-"],
        stations: ["FQC", "IQC"]
    },
    {
        name: "SONY",
        departments: ["CWZ620"],
        lines: ["-"],
        stations: ["FQC"]
    },
    {
        name: "QC",
        departments: ["CWZ620"],
        lines: ["-"],
        stations: ["Leader"]
    },
    {
        name: "WH",
        departments: ["CWZB00"],
        lines: ["-"],
        stations: ["-"]
    },
    {
        name: "Equipment Room",
        departments: ["CWZE30"],
        lines: ["-"],
        stations: ["-"]
    },
    {
        name: "Company Support",
        departments: ["CWZXXX"],
        lines: ["-"],
        stations: ["-"]
    },
    {
        name: "Quality Engineer",
        departments: ["CWZ610"],
        lines: ["-"],
        stations: ["-"]
    }
];