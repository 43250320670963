import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import ProjectNew from "../../models/ProjectNew";
import Department, { departmentList } from "../../models/Department";

interface EditProjectDialogProps {
    projectToEdit: ProjectNew,
    setProjectToEdit: (project: ProjectNew) => void,
}

export default function EditProjectDialog(props: EditProjectDialogProps) {
    const { projectToEdit, setProjectToEdit } = props;

    const handleClickCancelDialog = () => {
        setProjectToEdit({} as ProjectNew);
    };

    return (
        <Dialog open={!!projectToEdit.id} fullWidth>
            <DialogTitle>Edit project</DialogTitle>

            <DialogContent>
                <TextField
                    variant="standard"
                    margin="dense"
                    id="name"
                    label={<>Project name<span style={{ color: "red" }}>*</span></>}
                    type="text"
                    value={projectToEdit.name}
                    fullWidth
                    onChange={(event) => {
                        setProjectToEdit({ ...projectToEdit, name: event.target.value });
                    }}
                />
                <FormControl variant="standard" fullWidth sx={{ mt: '8px', mb: '4px' }}>
                    <InputLabel id="departmentId">Department<span style={{ color: "red" }}>*</span></InputLabel>
                    <Select
                        margin="dense"
                        id="departmentId"
                        type="text"
                        value={projectToEdit.departmentId}
                        fullWidth
                        onChange={(event) => {
                            setProjectToEdit({ ...projectToEdit, departmentId: event.target.value });
                        }}
                    >
                        {departmentList.map((department: Department) => <MenuItem key={department.id} value={department.id}>{department.id} - {department.description}</MenuItem>)}
                    </Select>
                </FormControl>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClickCancelDialog}>Cancel</Button>
                <Button variant="contained">Save</Button>
            </DialogActions>
        </Dialog>
    );
}
