import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";

interface EditStationDialogProps {
    stationId: string,
    setStationIdToEdit: (stationId: string) => void,
}

export default function EditStationDialog(props: EditStationDialogProps) {
    const { stationId, setStationIdToEdit } = props;

    const handleClickCancelDialog = () => {
        setStationIdToEdit("");
    };

    return (
        <Dialog open={Boolean(stationId)} fullWidth>
            <DialogTitle>Edit station</DialogTitle>

            <DialogContent>
                <TextField
                    variant="standard"
                    margin="dense"
                    id="name"
                    label={<>Station name<span style={{ color: "red" }}>*</span></>}
                    type="text"
                    value={stationId}
                    fullWidth
                    onChange={(event) => {

                    }}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClickCancelDialog}>Cancel</Button>
                <Button variant="contained">Save</Button>
            </DialogActions>
        </Dialog>
    );
}