import { useQuery } from "@tanstack/react-query";
import React, { useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { fetchGet, hasRole, LayoutContext } from "wcz-layout";
import { headList } from "../../models/Head";
import HeadcountSummaryModel from "../../models/HeadcountSummaryModel";
import { apiUrl } from "../../utils/BaseUrl";

interface PrivateRouteProps {
    children: React.ReactElement,
}

export default function PrivateRoute(props: PrivateRouteProps): React.ReactElement {
    const { user } = useContext(LayoutContext);
    const { children } = props;
    const { pathname } = useLocation();
    const pathEmployeeId = useMemo(() => pathname.includes("/daily-workload") && pathname.split('/').length === 3 ? pathname.split('/').at(-1) : undefined, [pathname]);
    const isHead = useMemo(() => headList.some(head => head.employeeId === user?.id), [user]);
    const headOf = useMemo(() => headList.filter(head => head.employeeId === user.id), [user]);
    const isITAdmin = useMemo(() => hasRole(["wcz-developers"]), [user]);
    var isAuthorized = false;

    const { data: hsEmployee } = useQuery<HeadcountSummaryModel>(["hsEmployee"], ({ signal }) => fetchGet(`${apiUrl}/sot/v1/hsemployee/employeeId/${pathEmployeeId}`, signal), { enabled: !!pathEmployeeId && !!user.name });

    if (isITAdmin) {
        isAuthorized = true;

    } else if (pathname === "/") {
        isAuthorized = true;

    } else if (pathname === "/headcount-summary") {
        isAuthorized = isHead;

    } else if (pathname.includes("/daily-workload") && hsEmployee) {
        if (headOf.some(h => h.departmentId === hsEmployee.departmentId && h.project === hsEmployee.project && h.line === hsEmployee.line)
            || user.id === pathEmployeeId
            || user.name === "Weidi Wu") { // TODO: Predelat na skupinu SOT adminu, ve ktere bude Weidi
            isAuthorized = true;
        }
    } else if (pathname === "/query-modify") {
        isAuthorized = isHead;
    }
    else if (pathname === "/settings") {
        if (user.name === "Weidi Wu") { // TODO: Predelat na skupinu SOT adminu, ve ktere bude Weidi
            isAuthorized = true;
        }
    }

    if (user.name && isAuthorized) {
        return children;
    }

    return <>Not authorized</>;
}