import { Card, CardHeader, Grid, IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import ProjectNew from "../../models/ProjectNew";
import LineSettings from "./LineSettings";
import { Edit, LinearScale, MoreVert } from "@mui/icons-material";
import { useState } from "react";
import EditProjectDialog from "./EditProjectDialog";

interface ProjectSettingsCardProps {
    project: ProjectNew,
}

export default function ProjectSettingsCard(props: ProjectSettingsCardProps) {
    const { project } = props;

    const [projectMenuAnchorEl, setProjectMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [openProjectMenuId, setOpenProjectMenuId] = useState<string>("");
    const handleClickOpenProjectMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setProjectMenuAnchorEl(event.currentTarget);
        setOpenProjectMenuId(event.currentTarget.id);
    };
    const handleCloseProjectMenu = () => {
        setProjectMenuAnchorEl(null);
        setOpenProjectMenuId("");
    };

    const [projectToEdit, setProjectToEdit] = useState<ProjectNew>({} as ProjectNew);
    const handleClickEditProject = () => setProjectToEdit(project);

    return (
        <Grid container padding="10px 20px">
            <Grid item xs={12} >
                <Card elevation={2}>
                    <CardHeader title={project.departmentId + ": " + project.name} sx={{ bgcolor: "#99BAC0" }}
                        action={<IconButton id={`project-menu-button-${project.id}`} onClick={handleClickOpenProjectMenu}><MoreVert /></IconButton>}
                    />
                    <Menu
                        id={`project-menu-${project.id}`}
                        anchorEl={projectMenuAnchorEl}
                        open={openProjectMenuId === `project-menu-button-${project.id}`}
                        onClose={handleCloseProjectMenu}
                    >
                        <MenuItem onClick={handleClickEditProject}>
                            <ListItemIcon>
                                <Edit fontSize="small" />
                            </ListItemIcon>
                            Edit project
                        </MenuItem>
                        <MenuItem>
                            <ListItemIcon>
                                <LinearScale fontSize="small" />
                            </ListItemIcon>
                            Add new line
                        </MenuItem>
                        <EditProjectDialog projectToEdit={projectToEdit} setProjectToEdit={setProjectToEdit} />
                    </Menu>
                    {
                        project.lines.map((line) => <LineSettings key={line.id} line={line} />)
                    }
                </Card>
            </Grid>
        </Grid>
    );
}