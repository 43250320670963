import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import moment from "moment";
import { useEffect, useState } from "react";
import { fetchPost, fetchPut, newGuid } from "wcz-layout";
import Break from "../../models/Break";
import DailyWorkloadModel from "../../models/DailyWorkloadModel";
import Department, { departmentList } from '../../models/Department';
import HeadcountSummaryModel from "../../models/HeadcountSummaryModel";
import Project, { projectList } from '../../models/Project';
import { apiUrl } from "../../utils/BaseUrl";

function calculateWorkedHours(dailyWorkload: DailyWorkloadModel, breaks: Break[]): string {
    if (!dailyWorkload.start || !dailyWorkload.end) return "";

    let workStart = moment(dailyWorkload.start, "H:mm");
    let workEnd = moment(dailyWorkload.end, "H:mm");

    let workDiff = workEnd.diff(workStart);
    let breakDiff = 0;

    //let breaks: Break[] = breakSchedule.filter(b => b.departmentId === employee.departmentId && b.project === employee.project && b.line === employee.line);
    for (let varBreak of breaks) {
        let breakStart = moment(varBreak.start, "H:mm");
        let breakEnd = moment(varBreak.end, "H:mm");

        // Work start and end time are both in the middle of the break
        if (workStart.isSameOrAfter(breakStart) && workEnd.isSameOrBefore(breakEnd)) {
            workDiff = 0;
            break;
        }

        // #1: The whole break is in the middle of the working time
        if (workStart.isBefore(breakStart) && workEnd.isAfter(breakEnd)) {
            breakDiff += breakEnd.diff(breakStart);
        }
        // #2: The work start is in the middle of the break or is the same as the break start time
        else if (workStart.isBetween(breakStart, breakEnd) || workStart.isSame(breakStart)) {
            breakDiff += breakEnd.diff(workStart);
        }
        // #3: The work end is in the middle of the break or is the same as the break end time
        else if (workEnd.isBetween(breakStart, breakEnd) || workEnd.isSame(breakEnd)) {
            breakDiff += workEnd.diff(breakStart);
        }
    }

    let workAndBreakDiffsInHours = moment.duration(workDiff - breakDiff);
    return moment.utc(workAndBreakDiffsInHours.asMilliseconds()).format("H:mm");
}

function calculateBreaktime(dailyWorkload: DailyWorkloadModel, breaks: Break[]): string {
    if (dailyWorkload.start === null || dailyWorkload.end === null) return "";

    let workStart = moment(dailyWorkload.start, "H:mm");
    let workEnd = moment(dailyWorkload.end, "H:mm");

    //let breaks: Break[] = breakSchedule.filter(b => b.departmentId === employee.departmentId && b.project === employee.project && b.line === employee.line);

    let breakDiff = 0;
    for (let varBreak of breaks) {
        let breakStart = moment(varBreak.start, "H:mm");
        let breakEnd = moment(varBreak.end, "H:mm");

        // Work start and end time are both in the middle of the break
        if (workStart.isSameOrAfter(breakStart) && workEnd.isSameOrBefore(breakEnd)) {
            breakDiff = workEnd.diff(workStart);;
            break;
        }

        // #1: The whole break is in the middle of the working time
        if (workStart.isBefore(breakStart) && workEnd.isAfter(breakEnd)) {
            breakDiff += breakEnd.diff(breakStart);
        }
        // #2: The work start is in the middle of the break or is the same as the break start time
        else if (workStart.isBetween(breakStart, breakEnd) || workStart.isSame(breakStart)) {
            breakDiff += breakEnd.diff(workStart);
        }
        // #3: The work end is in the middle of the break or is the same as the break end time
        else if (workEnd.isBetween(breakStart, breakEnd) || workEnd.isSame(breakEnd)) {
            breakDiff += workEnd.diff(breakStart);
        }
    }

    if (breakDiff === 0) {
        return "";
    }

    let breakDiffInHours = moment.duration(breakDiff);
    return moment.utc(breakDiffInHours.asMilliseconds()).format("H:mm");
}

function calculateOvertime(employee: HeadcountSummaryModel, dailyWorkload: DailyWorkloadModel, breaks: Break[]): string {
    if (!employee.workshiftEnd || employee.workshiftEnd === "00:00") return "";
    if (dailyWorkload.isWeekend || dailyWorkload.isHoliday) return dailyWorkload.worked ?? "";

    let workStart = moment(dailyWorkload.start, "H:mm");
    let workEnd = moment(dailyWorkload.end, "H:mm");
    let workshiftEnd = moment(employee.workshiftEnd, "H:mm");
    let overtime = "";
    if (workEnd.isAfter(workshiftEnd)) {
        overtime = moment(workEnd).subtract(moment.duration(employee.workshiftEnd)).format("H:mm");

        let breaktimeDuration = 0;
        let overtimeBreaks: Break[] = breaks.filter(b => b.type === "Overtime1" || b.type === "Overtime2");
        overtimeBreaks.forEach((element) => {
            let breakStart = moment(element.start, "H:mm");
            let breakEnd = moment(element.end, "H:mm");
            if (breakStart.isAfter(workStart) && breakEnd.isSameOrBefore(workEnd)) {
                breaktimeDuration += element.durationInMinutes;
            }
        });

        if (breaktimeDuration) {
            overtime = moment(overtime, "H:mm").subtract(moment.duration(breaktimeDuration, "minutes")).format("H:mm");
        }
    }

    return overtime;
}

interface StartSupportDialogProps {
    open: boolean,
    setOpen: (open: boolean) => void, 
    employee: HeadcountSummaryModel,
    rows: DailyWorkloadModel[],
    setRows: (rows: DailyWorkloadModel[]) => void,
    currentBreak: Break,
    breaks: Break[],
    setWorkingStatus: (status: number) => void,
    setDisableFastClicking: (value: boolean) => void,
}

export default function StartSupportDialog(props: StartSupportDialogProps) {
    const [departmentId, setDepartmentId] = useState("");
    const [project, setProject] = useState("");
    const [line, setLine] = useState("");
    const [station, setStation] = useState("");
    const [showErrors, setShowErrors] = useState(false);

    useEffect(() => {
        setDepartmentId(props.employee.departmentId);
        setProject(props.employee.project);
        setLine(props.employee.line);
        setStation(props.employee.station);        
    }, [props.employee, props.open]);

    const cleanForm = () => {        
        setDepartmentId("");
        setProject("");
        setLine("");
        setStation("");
        setShowErrors(false);
    }

    const handleClickCancelDialog = () => {
        cleanForm();
        props.setOpen(false);
    };

    const { mutate: postNewDailyWorkload } = useMutation((request: DailyWorkloadModel) => fetchPost(`${apiUrl}/sot/v1/dailyworkload`, request));
    const { mutate: putUpdatedDailyWorkload } = useMutation((request: DailyWorkloadModel) => fetchPut(`${apiUrl}/sot/v1/dailyworkload/${request.id}`, request));

    const canBeSaved = () => {
        let validationResult = true;
        if (!departmentId || !project || !line || !station) validationResult = false;

        if (!validationResult) setShowErrors(true);
        return validationResult;
    };

    const handleClickStartSupport = () => {
        if (!canBeSaved()) return;

        let now = moment();
        var newDailyWorkload: DailyWorkloadModel = {
            id: newGuid(),
            day: now.format("YYYY-MM-DDT00:00:00"),
            employeeId: props.employee.employeeId,
            departmentId: props.employee.departmentId,
            workloadDepartmentId: departmentId,
            position: props.employee.position,
            project: project,
            line: line,
            station: station,
            start: now.format("H:mm"),
            end: null,
            isSupport: departmentId !== props.employee.departmentId ? true : false,
            isWeekend: now.day() === 0 || now.day() === 6 ? true : false,
            isHoliday: false,
            isGymVisit: false
        }

        let todayWorkloads = props.rows.filter(r => r.day === now.format("YYYY-MM-DDT00:00:00"));
        if (todayWorkloads.length === 1 && todayWorkloads[0].start === null) {
            postNewDailyWorkload(newDailyWorkload);
            let filteredRows = props.rows.filter(r => r.day !== now.format("YYYY-MM-DDT00:00:00"));
            props.setRows([...filteredRows, newDailyWorkload]);
        }
        else {
            let lastRow = props.rows[props.rows.length - 1];
            if (lastRow && lastRow.end === null) {
                lastRow.end = now.format("H:mm");
                lastRow.worked = calculateWorkedHours(lastRow, props.breaks);
                lastRow.breaktime = calculateBreaktime(lastRow, props.breaks);
                lastRow.overtime = calculateOvertime(props.employee, lastRow, props.breaks);
                putUpdatedDailyWorkload(lastRow);
                props.setRows([...props.rows.filter(r => r.id !== lastRow.id), lastRow]);
            }
            postNewDailyWorkload(newDailyWorkload);
            props.setRows([...props.rows, newDailyWorkload]);
        }

        props.setWorkingStatus(2);
        props.setDisableFastClicking(true);
        cleanForm();
        props.setOpen(false);
    };

    return (
        <Dialog open={props.open}>
            <DialogTitle>Start support at other station</DialogTitle>

            <DialogContent sx={{ minWidth: "475px", maxWidth: "475px" }}>
                <FormControl variant="standard" fullWidth sx={{ mt: '8px', mb: '4px' }}>
                    <InputLabel id="departmentId">Department<span style={{ color: "red" }}>*</span></InputLabel>
                    <Select
                        margin="dense"
                        id="departmentId"
                        type="text"
                        value={departmentId}
                        fullWidth
                        onChange={(event) => {
                            setDepartmentId(event.target.value);
                            setProject("");
                            setLine("");
                            setStation("");
                        }}
                        error={showErrors && !departmentId}
                    >
                        {departmentList.map((department: Department) => <MenuItem key={department.id} value={department.id}>{department.id} - {department.description}</MenuItem>)}
                    </Select>
                    <FormHelperText sx={{ color: "#d32f2f" }}>{showErrors && !departmentId ? "Field 'Department' is required!" : ""}</FormHelperText>
                </FormControl>
                <FormControl variant="standard" fullWidth sx={{ mt: '8px', mb: '4px' }}>
                    <InputLabel id="project">Project<span style={{ color: "red" }}>*</span></InputLabel>
                    <Select
                        margin="dense"
                        id="project"
                        type="text"
                        value={project}
                        fullWidth
                        onChange={(event) => {
                            let lines = projectList.find(p => p.name === event.target.value && p.departments.includes(departmentId))?.lines ?? [];
                            setProject(event.target.value);
                            setLine(lines?.length === 1 ? lines[0] : "");
                            setStation("");
                        }}
                        error={showErrors && !project}
                    >
                        {projectList.filter(p => p.departments.includes(departmentId)).map((project: Project) => <MenuItem key={project.name} value={project.name}>{project.name}</MenuItem>)}
                    </Select>
                    <FormHelperText sx={{ color: "#d32f2f" }}>{showErrors && !project ? "Field 'Project' is required!" : ""}</FormHelperText>
                </FormControl>
                <FormControl variant="standard" fullWidth sx={{ mt: '8px', mb: '4px' }}>
                    <InputLabel id="line">Line<span style={{ color: "red" }}>*</span></InputLabel>
                    <Select
                        margin="dense"
                        id="line"
                        type="text"
                        value={line}
                        fullWidth
                        onChange={(event) => {
                            setLine(event.target.value);
                        }}
                        error={showErrors && !line}
                    >
                        {projectList.find(p => p.name === project && p.departments.includes(departmentId))?.lines.map((line: string) => <MenuItem key={line} value={line}>{line}</MenuItem>)}
                    </Select>
                    <FormHelperText sx={{ color: "#d32f2f" }}>{showErrors && !line ? "Field 'Line' is required!" : ""}</FormHelperText>
                </FormControl>
                <FormControl variant="standard" fullWidth sx={{ mt: '8px', mb: '4px' }}>
                    <InputLabel id="station">Station<span style={{ color: "red" }}>*</span></InputLabel>
                    <Select
                        margin="dense"
                        id="station"
                        type="text"
                        value={station}
                        fullWidth
                        onChange={(event) => {
                            setStation(event.target.value);
                        }}
                        error={showErrors && !station}
                    >
                        {projectList.find(p => p.name === project && p.departments.includes(departmentId))?.stations.map((station: string) => <MenuItem key={station} value={station}>{station}</MenuItem>)}
                    </Select>
                    <FormHelperText sx={{ color: "#d32f2f" }}>{showErrors && !station ? "Field 'Station' is required!" : ""}</FormHelperText>
                </FormControl>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClickCancelDialog}>Cancel</Button>
                <Button onClick={handleClickStartSupport}>Start support</Button>
            </DialogActions>
        </Dialog>
    );
}