export default interface Head {
    employeeId: string,
    name: string,
    level: string,
    departmentId: string,
    project: string,
    line: string,
};

export const headList: Head[] = [
    // CWZE10-MB Repair
    { employeeId: "CS1012051", name: "Lukas Krejci", level: "Leader", departmentId: "CWZE10", project: "MB Repair", line: "-" },
    { employeeId: "8308156", name: "Arlene Huang", level: "Supervisor", departmentId: "CWZE10", project: "MB Repair", line: "-" },
    { employeeId: "P06A349", name: "Reagan Donatos", level: "Manager", departmentId: "CWZE10", project: "MB Repair", line: "-" },
    // CWZE10-FAE
    { employeeId: "P06B173", name: "Carmina Santos", level: "Leader", departmentId: "CWZE10", project: "FAE", line: "-" },
    { employeeId: "P06B173", name: "Carmina Santos", level: "Supervisor", departmentId: "CWZE10", project: "FAE", line: "-" },
    { employeeId: "8308156", name: "Arlene Huang", level: "Manager", departmentId: "CWZE10", project: "FAE", line: "-" },
    // CWZE11-IRR-Line1
    { employeeId: "CS0911015", name: "Jiri Zmeskal", level: "Leader", departmentId: "CWZE11", project: "IRR", line: "1" },
    { employeeId: "CS1012051", name: "Lukas Krejci", level: "Supervisor", departmentId: "CWZE11", project: "IRR", line: "1" },
    { employeeId: "P06A349", name: "Reagan Donatos", level: "Manager", departmentId: "CWZE11", project: "IRR", line: "1" },
    // CWZE11-IRR-Line2
    { employeeId: "CS0911011", name: "Pavel Svojanovsky", level: "Leader", departmentId: "CWZE11", project: "IRR", line: "2" },
    { employeeId: "CS1012051", name: "Lukas Krejci", level: "Supervisor", departmentId: "CWZE11", project: "IRR", line: "2" },
    { employeeId: "P06A349", name: "Reagan Donatos", level: "Manager", departmentId: "CWZE11", project: "IRR", line: "2" },
    // CWZE11-HP FLEX
    { employeeId: "CS2009007", name: "Roman Pavelka", level: "Leader", departmentId: "CWZE11", project: "HP FLEX", line: "-" },
    { employeeId: "CS0904019", name: "Roman Hajek", level: "Supervisor", departmentId: "CWZE11", project: "HP FLEX", line: "-" },
    { employeeId: "8308156", name: "Arlene Huang", level: "Manager", departmentId: "CWZE11", project: "HP FLEX", line: "-" },
    // CWZE12-Dell OOW
    { employeeId: "CS1709043", name: "Ondrej Vymazal", level: "Leader", departmentId: "CWZE12", project: "Dell OOW", line: "-" },
    { employeeId: "CS1202008", name: "Jaroslav Tunkr", level: "Supervisor", departmentId: "CWZE12", project: "Dell OOW", line: "-" },
    { employeeId: "P06A349", name: "Reagan Donatos", level: "Manager", departmentId: "CWZE12", project: "Dell OOW", line: "-" },
    // CWZE12-Dell IW
    { employeeId: "CS1709043", name: "Ondrej Vymazal", level: "Leader", departmentId: "CWZE12", project: "Dell IW", line: "-" },
    { employeeId: "CS1202008", name: "Jaroslav Tunkr", level: "Supervisor", departmentId: "CWZE12", project: "Dell IW", line: "-" },
    { employeeId: "P06A349", name: "Reagan Donatos", level: "Manager", departmentId: "CWZE12", project: "Dell IW", line: "-" },
    // CWZE12-SONY
    { employeeId: "CS1605012", name: "Zdenek Wolf", level: "Leader", departmentId: "CWZE12", project: "SONY", line: "-" },
    { employeeId: "CS1202008", name: "Jaroslav Tunkr", level: "Supervisor", departmentId: "CWZE12", project: "SONY", line: "-" },
    { employeeId: "8902804", name: "Alan Luo", level: "Manager", departmentId: "CWZE12", project: "SONY", line: "-" },
    // CWZE13-BGA
    { employeeId: "CS1107033", name: "Michal Midrla", level: "Leader", departmentId: "CWZE13", project: "BGA", line: "-" },
    { employeeId: "CS1012051", name: "Lukas Krejci", level: "Supervisor", departmentId: "CWZE13", project: "BGA", line: "-" },
    { employeeId: "P06A349", name: "Reagan Donatos", level: "Manager", departmentId: "CWZE13", project: "BGA", line: "-" },
    // CWZE13-Re-ball
    { employeeId: "CS1107033", name: "Michal Midrla", level: "Leader", departmentId: "CWZE13", project: "Re-ball", line: "-" },
    { employeeId: "CS1012051", name: "Lukas Krejci", level: "Supervisor", departmentId: "CWZE13", project: "Re-ball", line: "-" },
    { employeeId: "P06A349", name: "Reagan Donatos", level: "Manager", departmentId: "CWZE13", project: "Re-ball", line: "-" },
    // CWZE20-E2E
    { employeeId: "CS0904019", name: "Roman Hajek", level: "Leader", departmentId: "CWZE20", project: "E2E", line: "-" },
    { employeeId: "CS0708013", name: "Pavel Krchnavy", level: "Supervisor", departmentId: "CWZE20", project: "E2E", line: "-" },
    { employeeId: "8308156", name: "Arlene Huang", level: "Manager", departmentId: "CWZE20", project: "E2E", line: "-" },
    // CWZE21-HP HP Def. Receiving
    { employeeId: "CS2008004", name: "Matej Flodr", level: "Leader", departmentId: "CWZE21", project: "HP Def. Receiving", line: "-" },
    { employeeId: "CS0708013", name: "Pavel Krchnavy", level: "Supervisor", departmentId: "CWZE21", project: "HP Def. Receiving", line: "-" },
    { employeeId: "8308156", name: "Arlene Huang", level: "Manager", departmentId: "CWZE21", project: "HP Def. Receiving", line: "-" },
    // CWZE22-HP FRU
    { employeeId: "CS2008004", name: "Matej Flodr", level: "Leader", departmentId: "CWZE22", project: "HP FRU", line: "-" },
    { employeeId: "CS0708013", name: "Pavel Krchnavy", level: "Supervisor", departmentId: "CWZE22", project: "HP FRU", line: "-" },
    { employeeId: "8308156", name: "Arlene Huang", level: "Manager", departmentId: "CWZE22", project: "HP FRU", line: "-" },
    // CWZE23-RTV
    { employeeId: "CS1103656", name: "Tomas Hejtman", level: "Leader", departmentId: "CWZE23", project: "RTV", line: "-" },
    { employeeId: "CS0708013", name: "Pavel Krchnavy", level: "Supervisor", departmentId: "CWZE23", project: "RTV", line: "-" },
    { employeeId: "8308156", name: "Arlene Huang", level: "Manager", departmentId: "CWZE23", project: "RTV", line: "-" },
    // CWZE11-WUR
    { employeeId: "CS0904019", name: "Roman Hajek", level: "Leader", departmentId: "CWZE11", project: "WUR", line: "-" },
    { employeeId: "CS0904019", name: "Roman Hajek", level: "Supervisor", departmentId: "CWZE11", project: "WUR", line: "-" },
    { employeeId: "8308156", name: "Arlene Huang", level: "Manager", departmentId: "CWZE11", project: "WUR", line: "-" },
    // CWZ620-HP OSSP SPF
    { employeeId: "CS1108125", name: "Ivan Miskovic", level: "Leader", departmentId: "CWZ620", project: "HP OSSP SPF", line: "-" },
    { employeeId: "CS1108125", name: "Ivan Miskovic", level: "Supervisor", departmentId: "CWZ620", project: "HP OSSP SPF", line: "-" },
    { employeeId: "10808101", name: "Weidi Wu", level: "Manager", departmentId: "CWZ620", project: "HP OSSP SPF", line: "-" },
    // CWZ620-IRR
    { employeeId: "CS1108125", name: "Ivan Miskovic", level: "Leader", departmentId: "CWZ620", project: "IRR", line: "-" },
    { employeeId: "CS1108125", name: "Ivan Miskovic", level: "Supervisor", departmentId: "CWZ620", project: "IRR", line: "-" },
    { employeeId: "10808101", name: "Weidi Wu", level: "Manager", departmentId: "CWZ620", project: "IRR", line: "-" },
    // CWZ620-HP FLEX
    { employeeId: "CS1108125", name: "Ivan Miskovic", level: "Leader", departmentId: "CWZ620", project: "HP FLEX", line: "-" },
    { employeeId: "CS1108125", name: "CS1108125", level: "Supervisor", departmentId: "CWZ620", project: "HP FLEX", line: "-" },
    { employeeId: "10808101", name: "Weidi Wu", level: "Manager", departmentId: "CWZ620", project: "HP FLEX", line: "-" },
    // CWZ620-Dell
    { employeeId: "CS1108125", name: "Ivan Miskovic", level: "Leader", departmentId: "CWZ620", project: "Dell", line: "-" },
    { employeeId: "CS1108125", name: "Ivan Miskovic", level: "Supervisor", departmentId: "CWZ620", project: "Dell", line: "-" },
    { employeeId: "10808101", name: "Weidi Wu", level: "Manager", departmentId: "CWZ620", project: "Dell", line: "-" },
    // CWZ620-HPTC
    { employeeId: "CS1108125", name: "Ivan Miskovic", level: "Leader", departmentId: "CWZ620", project: "HPTC", line: "-" },
    { employeeId: "CS1108125", name: "Ivan Miskovic", level: "Supervisor", departmentId: "CWZ620", project: "HPTC", line: "-" },
    { employeeId: "10808101", name: "Weidi Wu", level: "Manager", departmentId: "CWZ620", project: "HPTC", line: "-" },
    // CWZ620-SONY
    { employeeId: "CS1108125", name: "Ivan Miskovic", level: "Leader", departmentId: "CWZ620", project: "SONY", line: "-" },
    { employeeId: "CS1108125", name: "Ivan Miskovic", level: "Supervisor", departmentId: "CWZ620", project: "SONY", line: "-" },
    { employeeId: "10808101", name: "Weidi Wu", level: "Manager", departmentId: "CWZ620", project: "SONY", line: "-" },
    // CWZ620-QC
    { employeeId: "CS1108125", name: "Ivan Miskovic", level: "Leader", departmentId: "CWZ620", project: "QC", line: "-" },
    { employeeId: "CS1108125", name: "Ivan Miskovic", level: "Supervisor", departmentId: "CWZ620", project: "QC", line: "-" },
    { employeeId: "10808101", name: "Weidi Wu", level: "Manager", departmentId: "CWZ620", project: "QC", line: "-" },
];