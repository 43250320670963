import { LayoutContext, fetchGet } from "wcz-layout";
import Project from "../models/Project";
import { apiUrl } from "../utils/BaseUrl";
import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { Box, Typography } from "@mui/material";
import ProjectNew from "../models/ProjectNew";
import ProjectSettingsCard from "../components/settings/ProjectSettingsCard";
import { useGetProjects } from "../queries/ProjectSettingsQueries";


export default function ProjectSettings() {
    const { user } = useContext(LayoutContext);
    const { data: projectList } = useGetProjects({ enabled: !!user.name });

    return (
        <Box>
            <Typography variant="h5" color="text.secondary" sx={{ pl: "25px", pt: "15px" }} gutterBottom component="div">Project Settings</Typography>
            {
                projectList?.map((project: ProjectNew) => <ProjectSettingsCard key={project.id} project={project} />)
            }
        </Box>
      );
}